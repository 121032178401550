<template>
    <div class="w-100">
        <div>
            <div class="alert alert-warning mb-0 mt-1">
                <div class="d-flex">
                    <div class="flex-fill">
                        <div v-if="isEditOddsOfRecv">
                            <el-input-number v-model="oddsOfRecv"  :min="0" :max="100" size="small"></el-input-number>
                            <button class="btn btn-primary ml-2  btn-sm"
                                    @click="updateOddsOfRecv">
                                Save change
                            </button>
                        </div>
                        <span v-else>Tỉ lệ được phân khách hàng mới không rõ nguồn là <strong>{{detailItem.oddsOfRecv}}%</strong>;</span>
                    </div>
                    <div>
                        <button class="btn btn-primary btn-sm" v-if="!isEditOddsOfRecv"
                                @click="oddsOfRecv = detailItem.oddsOfRecv; isEditOddsOfRecv = !isEditOddsOfRecv">
                            Modify
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-eq-height">
            <div class="col-xs-12 col-sm-6 col-md-4 d-flex">
                <div class="card p-2 flex-fill d-flex flex-column" style="max-height: calc(100dvh - 15rem);">
                    <div><strong>AVAIABLE USERS</strong></div>
                    <VuePerfectScrollbar class="flex-fill" >
                        <ul class="users-list department clearfix w-100">
                            <li :key="userItem.id" v-for="userItem in userAvailable" class="cursor-pointer">
                                <div class="position-relative w-100">
                                    <img :src="`/img/avatars/${userItem.email}.jpg`" 
                                         onerror="this.src='/img/avatar5.png'"
                                         :alt="userItem.fullName" class=" w-100">                                
                                    <button class="position-absolute btn-select-user"                                             
                                            @click="addToDept(userItem)">
                                        <i class="fas fa-plus fa-2x"></i>
                                    </button>
                                </div>
                                <a class="users-list-name" href="javascript:void(0);">{{userItem.fullName}}</a>
                            </li>
                        </ul>
                    </VuePerfectScrollbar>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-8 d-flex">
                <div class="card p-2 flex-fill  d-flex flex-column" 
                     style="max-height: calc(100dvh - 15rem);">
                    <div><strong>{{detailItem.name}} OWNED USER</strong></div>
                    <VuePerfectScrollbar  class="flex-fill">
                        <ul v-if="userOfDept.length > 0" class="users-list clearfix w-100">
                            <li :key="userItem.id" v-for="userItem in userOfDept" 
                                class="cursor-pointer w-25">
                                <div class="position-relative w-100">
                                    <img :src="`/img/avatars/${userItem.email}.jpg`" 
                                         onerror="this.src='/img/avatar5.png'"
                                         :alt="userItem.fullName" class=" w-100">                                
                                    <button class="position-absolute btn-select-user"                                             
                                            @click="removeFromDept(userItem)">
                                        <i class="fas fa-minus fa-2x"></i>
                                    </button>
                                </div>
                                <a class="users-list-name" href="javascript:void(0);">{{userItem.fullName}}</a>
                            </li>
                        </ul>
                        <div v-else class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                            <div class="align-self-center text-center">
                                <div class="mb-5"><img src="/static/image/no-job-icon.svg" style="opacity: 0.7;"></div>
                                <h4><strong class="text-muted">There is no user has been added</strong></h4>
                                <p class="text-muted">Click user on the left to add user to {{detailItem.name}}!</p>
                            </div>
                        </div>
                    </VuePerfectScrollbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import commonAct from '@/commonActionHandle.js';
    import baseComponent from "@/scripts/baseComponent";
    import authApi from "@/api/common/auth";
    import departmentApi from '@/api/administrator/department';
    export default {
        extends: baseComponent,
        props: {
            detailItem: {
                type: Object,
                default: null,
            }
        },
        watch: {
            detailItem(newVal) {
                this.loadAvailableUserList(this.detailItem.idDept);
                this.loadUserListOfDept(this.detailItem.idDept);
                this.oddsOfRecv = this.detailItem.oddsOfRecv;
            }
        },
        data() {
            return {
                userAvailable: [],
                userOfDept: [],
                isEditOddsOfRecv : false,
                oddsOfRecv : 0,
            };
        },
        mounted() { 
            this.loadAvailableUserList(this.detailItem.idDept);
            this.loadUserListOfDept(this.detailItem.idDept);
        },
        methods: {
            loadAvailableUserList(idDept) {
                authApi.getAllUsersNotInDept(idDept).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.userAvailable = response.data.data;
                    }
                    else {
                        this.userAvailable = [];
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    this.userAvailable = [];
                });
            },
            loadUserListOfDept(idDept) {
                authApi.getUserOfDepartment(idDept).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.userOfDept = response.data.data;
                    }
                    else {
                        this.userOfDept = [];
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    this.userOfDept = [];
                });
            },
            addToDept(item) {
                departmentApi.addUserToDepartment(this.detailItem.idDept, item.id).then(response => {
                    if (response.data && response.data.result === 0) {                        
                        this.userOfDept.push(item);
                        let indexItem = this.userAvailable.findIndex(x => x.id === item.id);
                        this.userAvailable.splice(indexItem, 1);
                        //this.showSuccessToast(`${item.fullName} has been added`);
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            removeFromDept(item) {
                departmentApi.removeUserToDepartment(this.detailItem.idDept, item.id).then(response => {
                    if (response.data && response.data.result === 0) {        
                        this.userAvailable.push(item);
                        let indexItem = this.userOfDept.findIndex(x => x.id === item.id);
                        this.userOfDept.splice(indexItem, 1);
                        //this.showSuccessToast(`${item.fullName} has been removed`);
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            updateOddsOfRecv() {                
                departmentApi.updateDepartmentOdds(this.detailItem.idDept, this.oddsOfRecv).then(response => {
                    if (response.data && response.data.result === 0) {        
                        this.$set(this.detailItem, "oddsOfRecv", this.oddsOfRecv);
                        this.showSuccessToast(`modify has been success`);
                        this.isEditOddsOfRecv = false;
                    }
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>
    .users-list.department > li {
        float: left;
        padding: 10px;
        text-align: center;
        width: 33%;
    }

    .btn-select-user {
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        border-radius: 500px;
        color: #fff;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 5;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.8);
    }

    .users-list > li:hover .btn-select-user {
        visibility: visible;
    }
</style>